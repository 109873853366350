/* eslint-disable no-unused-expressions */
import { requiredIf, helpers, maxLength } from 'vuelidate/lib/validators';

const plateCheck = helpers.regex(
  'plateCheck',
  /^(0[1-9]|[1-7][0-9]|8[01])(([A-Z])(\d{4,5})|([A-Z]{2})(\d{3,4})|([A-Z]{3})(\d{2,4}))$/,
);

// ^[A-Za-z0-9]+|[\b]+$/

const damageValidationMixin = {
  validations: {
    damage: {
      additionalDamageCost: {
        required: requiredIf((x) => x.hasAdditionalDamageCost),
      },
      plateNumber: {
        plateCheck,
      },
      chassisNumber: {
        maxLength: maxLength(17),
      },
    },
  },
  methods: {
    additionalDamageCostErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.damage.additionalDamageCost;
      if (!$dirty) return errors;
      !required && errors.push('Lütfen bir tramer tutarı giriniz.');
      return errors;
    },
    plateCheckErrors() {
      const errors = [];
      const { $dirty, plateCheck } = this.$v.damage.plateNumber;
      if (!$dirty) return errors;
      !plateCheck && errors.push('Girmiş olduğunuz plaka formatı doğru değil!');
      return errors;
    },
    chassisNumberErrors() {
      const errors = [];
      const { $dirty, maxLength } = this.$v.damage.chassisNumber;
      if (!$dirty) return errors;
      !maxLength && errors.push('Şase numarası en fazla 17 karakter olabilir.');
      return errors;
    },
  },
};

export default damageValidationMixin;
